@import '../../../styles/variables.module.scss';

.container {
  height: 70px;
  overflow: hidden;
  padding: 0 16px;
  // width: 100%;
  cursor: pointer;
  &:hover {
    background-color: $colorExtraLightGrey;
    border-radius: 5px;
  }
}
.containerInner {
  height: 100%;
}
.containerIcon {
  display: flex;
}
.columnHeader,
.columnText {
  margin: 0;
}
.columnHeader {
  font-style: italic;
  margin-bottom: 4px;
}
.columnText {
  font-size: 14px;
}
.containerTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (min-width: 1200px) {
  .columnContainer {
    margin: 0 12px;
  }
}
@media screen and (max-width: 1199px) {
  .containerTitle {
    padding-right: 12px;
  }
}
