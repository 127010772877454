@import '../../../styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $colorWhite;
  max-width: 240px;
  min-width: 240px;
  overflow: hidden;
  height: 100%;
}

.buttonsContainer {
  padding: 1em;
  flex-direction: row;
}

.highlightButtons {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  justify-content: space-between;
}

.contentContainer {
  flex: 1;
  background: $colorExtraLightGrey;
  overflow: scroll;
}

.separator {
  height: 0.5px;
  width: 100%;
  background-color: $colorLightGrey;
}

.loadingIndicator {
  display: flex;
  height: 60%;
}
