@import '../../styles/variables.module.scss';

.container {
  background-color: $colorWhite;
  width: 320px;
  min-height: 400px;
  padding: 16px 24px;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - (380px / 2));
  left: calc(50% - (320px / 2));
  outline: 0;
  align-items: center;
  display: flex;
}

.contentContainer {
  flex: 1;
}

.profileForm {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icon {
  color: grey;
  margin-bottom: 16px;
}

.passChangedLabel {
  flex: 1;
  display: flex;
  justify-content: center;
}

.loadingSpinner {
  flex: 1;
  display: flex;
}
