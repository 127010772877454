@import '../../../styles/variables.module.scss';

.buttonsContainer {
  padding: 1em;
  flex-direction: row;
}

.containerSelect {
  margin-bottom: 14px;
  margin-top: 14px;
}
.container {
  display: flex;
  flex-direction: column;
  background-color: $colorWhite;
  max-width: 240px;
  min-width: 240px;
  overflow: hidden;
  height: 100%;
}

.separator {
  height: 0.5px;
  width: 100%;
  background-color: $colorLightGrey;
}

.contentContainer {
  flex: 1;
  background: $colorExtraLightGrey;
  overflow: scroll;
}

.numberButton {
  background-color: $colorExtraLightGrey;
  color: $colorDarkNav;
  width: 50%;
  margin-right: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maxNumberLabel {
  margin-right: 10px;
  text-align: center;
}
