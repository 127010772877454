@import './variables.module.scss';

.cyan {
  background-color: $colorPrimary;
  cursor: pointer;
}

.yellow {
  background-color: $colorHighlightYellow;
  cursor: pointer;
}

.red {
  background-color: $colorHighlightRed;
  cursor: pointer;
}

.comment {
  text-decoration: underline;
  text-decoration: underline;
  cursor: pointer;
}
