@import './../../../styles/variables.module.scss';

.container {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: $colorExtraLightGrey;
}

.quillContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  height: 100%;
  width: 100%;
}
