@import './../../../styles/variables.module.scss';

.pdfContainer {
  height: 100%;
  background-color: $colorExtraLightGrey;
}
.containerInnerPdf {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.pageButton {
  margin: auto 0;
}
.containerLoading {
  width: 100%;
  height: 100%;
  flex: 1;
}
