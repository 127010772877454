$colorPrimary: #30bfc5;
$colorSecondary: #494c53;
$colorGreen: #b9d538;

$colorDarkNav: #535c5d;
$colorDarkAppBar: #42464b;
$colorWhite: #fff;
$colorBlack: #000;
$colorMenuDivider: #4d565a;
$colorExtraLightGrey: #ecebec;
$colorLightGrey: #c4c4c4;
$colorGrey: #747474;
$colorDarkGrey: #404040;
$summaryButtonsColor: #6e767a;
$colorContainerLightGrey: #d3d3d3;
$colorHighlightYellow: #ffd21b;
$colorHighlightRed: #ff7070;
$transparent: transparent;
$colorRed: #ff0000;
$colorInputLabel: #808080;

$appBarHeight: 66px;
$verticalMenuWidth: 55px;
$dashboardToolBarHeight: 70px;
$bookContainerHeight: 354px;
$dashboardButtonContainer: 70px;

:export {
  colorPrimary: $colorPrimary;
  colorSecondary: $colorSecondary;
  colorGreen: $colorGreen;

  colorGrey: $colorGrey;
  colorDarkGrey: $colorDarkGrey;
  colorDarkNav: $colorDarkNav;
  colorWhite: $colorWhite;
  colorBlack: $colorBlack;
  colorMenuDivider: $colorMenuDivider;
  colorLightGrey: $colorLightGrey;
  colorExtraLightGrey: $colorExtraLightGrey;
  colorContainerLightGrey: $colorContainerLightGrey;
  colorHighlightCyan: $colorPrimary;
  colorHighlightYellow: $colorHighlightYellow;
  colorHighlightRed: $colorHighlightRed;
  colorInputLabel: $colorInputLabel;

  appBarHeight: $appBarHeight;
  verticalMenuWidth: $verticalMenuWidth;
  dashboardToolBarHeight: $dashboardToolBarHeight;
  bookContainerHeight: $bookContainerHeight;
  dashboardButtonContainer: $dashboardButtonContainer;
  transparent: $transparent;
  summaryButtonsColor: $summaryButtonsColor;
}
