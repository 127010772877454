.commentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 14px;
}

.buttonContainers {
  display: flex;
  flex: 1;
  flex-direction: row;
}
