@import '../../../styles/variables.module.scss';

.container {
  max-width: 320px;
  background-color: white;
  border-radius: 10px;
  height: 40%;
  padding: 14px;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.6);
  flex: 1;
}

.filterForm {
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60%;
}

.Header {
  width: 100%;
  height: 50px;
}

.Header img {
  float: left;
  max-width: 104px;
  max-height: 52px;
  width: auto;
  height: auto;
}

.Header button.red {
  float: right;
  margin-top: 5px;
}

.Header h1 {
  display: inline-block;
  float: left;
  margin-left: 10px;
  font-size: 1.1em;
  margin-top: 7px;
}

.fieldContainer {
  flex-direction: row;
  display: flex;
  flex: 1;
}

.filterContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.deleteText {
  text-align: left;
}

.confirmDeleteContainer {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 2em;
}

.confirmDeleteButtons {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 1em;
}
