@import './../../../styles/variables.module.scss';
@import '../../../styles/annotations.module.scss';

.popupContentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 8px;
  background-color: $colorDarkNav;
}

.colorButtonContainer {
  overflow: hidden;
  border-radius: 4px;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.linkButtonContainer {
  display: flex;
  flex-direction: row;
}

.cyan {
  @extend .cyan;
}

.yellow {
  @extend .yellow;
}

.red {
  @extend .red;
}

.comment {
  @extend .comment;
}

.purple {
  background-color: purple;
}

.lastComment {
  text-decoration: underline;
  cursor: pointer;
}

.lastComment:after {
  content: url(../../../assets/Icons/icon-comment.png);
  position: relative;
  padding-left: 5px;
  top: -5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
