@import '../../../styles/variables.module.scss';

.bookContainer {
  padding-left: 2em;
  padding-bottom: 0.5em;
  display: flex;
  white-space: nowrap;
  overflow: auto;
  min-height: 280px;
}

.headerContainer {
  padding-left: 2em;
}

.headerStyle {
  margin: 0;
  color: $colorPrimary;
  font-weight: 400;
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.emptyPlaceholderContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.emptyPlaceholderText {
  color: $colorDarkGrey;
  font-weight: 400;
}
