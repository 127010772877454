@import '../../../styles/variables.module.scss';

.bookGrid {
  margin-right: 2.5em;
  width: 162px;
  cursor: pointer;
}

.cover:hover {
  opacity: 0.4;
}

.link {
  text-decoration: none;
  color: black;
}

.cover {
  width: 140px;
  height: 180px;
}

.coverPlaceholder {
  background-color: $colorGrey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverIcon {
  color: $colorWhite;
}

.title {
  margin-block-start: 12px;
  margin-block-end: 4px;
  font-size: 1em;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #404040;
  font-weight: 400;
}

.author {
  margin-block-start: 0%;
  margin-block-end: 2px;
  font-size: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 300;
}

.moreIconContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 2px;
}
