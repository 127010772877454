@import '../../../styles/variables.module.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 1em;
  padding-left: 1em;
  height: calc(
    100vh - #{$appBarHeight + $dashboardToolBarHeight +
      $bookContainerHeight + $dashboardButtonContainer}
  );
}

.headerStyle {
  color: $colorPrimary;
  font-weight: 400;
  margin-block-start: 0.7em;
  margin-block-end: 0.5em;
}

.listItems {
  overflow: scroll;
}

.listItems::-webkit-scrollbar {
  width: 0 !important;
}

.emptyPlaceholderContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.emptyPlaceholderText {
  color: $colorDarkGrey;
  font-weight: 400;
}
