@import '../../../styles/variables.module.scss';

.container {
  max-width: 320px;
  background-color: white;
  border-radius: 10px;
  padding: 14px;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.6);
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.removeButton {
  padding: 4px 4px 4px 4px !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
  color: black !important;
  background-color: $colorContainerLightGrey !important;
  margin-bottom: 6px !important;
  margin-left: 8px !important;
}

.headerContainer p {
  // display: inline-block;
  margin-top: 10px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  color: $colorPrimary;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

.qmark {
  color: black;
}
