@import '../../styles/variables.module.scss';

.link {
  font-size: 10px;
  margin-left: 30px;
  margin-bottom: 10px;
}

.dashboardContainer {
  height: calc(
    100vh - #{$appBarHeight + $dashboardToolBarHeight}
  );
}

.pdfNotesContainer {
  display: flex;
  flex: 1;
}

.loadingBook {
  height: $bookContainerHeight;
  display: flex;
  flex: 1;
}

.loadingNotes {
  height: calc(
    100vh - #{$appBarHeight + $dashboardToolBarHeight +
      $bookContainerHeight + $dashboardButtonContainer}
  );
  display: flex;
  flex: 1;
}

.loadingUploads {
  height: calc(
    100vh - #{$appBarHeight + $dashboardToolBarHeight +
      $bookContainerHeight + $dashboardButtonContainer}
  );
  display: flex;
  flex: 1;
}
