@import '../../../styles/variables.module.scss';

.container {
  max-width: 320px;
  background-color: white;
  border-radius: 10px;
  height: 40%;
  padding: 14px;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.6);
  flex: 1;
}

.inputContainer {
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.filterInput {
  width: 180px;
  margin: 8px 0;
  height: 28px;
  color: #535c5d;
  font-size: 12px;
  font-weight: bold;
}

.saveButton {
  padding: 4px 4px 4px 4px !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
  margin-top: 8px !important;
  color: black !important;
  background-color: lightGrey !important;
}
