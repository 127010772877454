@font-face {
  font-family: 'Titillium';
  src: url('../assets/fonts/Titillium-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium';
  src: url('../assets/fonts/Titillium-RegularItalic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Titillium';
  src: url('../assets/fonts/Titillium-Semibold.otf')
    format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium';
  src: url('../assets/fonts/Titillium-SemiboldItalic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Titillium';
  src: url('../assets/fonts/Titillium-Bold.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium';
  src: url('../assets/fonts/Titillium-BoldItalic.otf')
    format('opentype');
  font-weight: 700;
  font-style: italic;
}
