@import '../../styles/variables.module.scss';

.container {
  height: $dashboardToolBarHeight;
  background-color: white;
  border-bottom: 1px solid $colorExtraLightGrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid $colorExtraLightGrey;
}
