@import '../../../styles/variables.module.scss';

.container {
  background-color: white;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}
.containerTitle {
  display: flex;
}
.bookTitle {
  margin-left: 8px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  color: $colorDarkNav;
}

.moreIcon {
  color: $colorDarkNav;
  margin-left: 12px;
}
.containerIcons {
  display: flex;
}
.iconSave {
  color: $colorDarkNav;
  margin-right: 24px;
  cursor: pointer;
}
